import React, { Component } from 'react'
import Modal from 'react-modal'
import ReactMarkdown from 'react-markdown'

import Divider from 'src/components/Divider'
import PurchaseButtonGroup from 'src/components/PurchaseButtonGroup'

import DesktopBody from './desktop'
import ModalFAQ from './ModalFAQ'
import HowToOrder from './HowToOrder'
import './style.scss'

import { Tabs } from 'antd'

const { TabPane } = Tabs

function callback(key) {
  // console.log(key)
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90vh',
    width: '95vw',
    maxWidth: '960px',
    overlfow: 'scroll'
  },
  overlay: { zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.75)' }
}

Modal.setAppElement(`#___gatsby`)

export default class CustomModal extends Component {
  getTag = (category, size) => {
    if (category === 'P') return <div className="tag small">พรีเมียม</div>
    else if (category === 'F') return <div className="tag xs">size {size}</div>
    else return ''
  }

  render() {
    const {
      isModalOpen,
      isShowTel,
      onToggleTel,
      onCloseModal,
      selectedProduct,
      watName,
      isFanPage
    } = this.props
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-wrapper mobile">
          <div className="close-box">
            <button onClick={onCloseModal}>
              <img
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/close_gold.png"
                alt="ปิด"
                width="28"
                height="28"
              />
            </button>
          </div>
          <div className="modal-image">
            <img
              src={selectedProduct.image ? selectedProduct.image : selectedProduct.imageS3}
              alt={selectedProduct.id}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Tabs onChange={callback} type="card">
              <TabPane tab="ข้อมูลสินค้า" key="1">
                <div className="modal-body">
                  <div className="product-desc-wrapper">
                    <div className="product-desc">
                      <div className="left-box">
                        <small>{selectedProduct.id}</small>
                        <p>฿{Number(selectedProduct.price).toLocaleString()}</p>
                        {this.getTag(selectedProduct.category, selectedProduct.size)}
                      </div>
                      <div
                        className={`right-box ${selectedProduct.category === 'F' ? 'xs-hide' : ''}`}
                      >
                        <ReactMarkdown source={selectedProduct.descMobile} />
                      </div>
                    </div>
                    {selectedProduct.m && (
                      <div className="product-desc border-left">
                        <div className="left-box">
                          <small>{selectedProduct.m.id}</small>
                          <p>฿{Number(selectedProduct.m.price).toLocaleString()}</p>
                          {this.getTag(selectedProduct.category, selectedProduct.m.size)}
                        </div>
                        <div className="right-box">
                          <ReactMarkdown source={selectedProduct.m.descMobile} />
                        </div>
                      </div>
                    )}
                  </div>
                  <Divider className="secondary" />
                  <PurchaseButtonGroup
                    buttonLabelMobile="คลิก Add line เพื่อสั่งซื้อ"
                    buttonId={`MDetail-${selectedProduct.category}_${selectedProduct.id}`}
                    isNoBorder={true}
                    isShowCall={true}
                    watName={watName}
                    isFanPage={isFanPage}
                    classNameFanRemark="modal-fan-remark"
                  />
                </div>
              </TabPane>
              <TabPane tab="วิธีการสั่งซื้อ" key="how-to-order">
                <div className="modal-body">
                  <HowToOrder />
                  <PurchaseButtonGroup
                    buttonLabelMobile="คลิก Add line เพื่อสั่งซื้อ"
                    buttonId={`HowToOrder-${selectedProduct.category}_${selectedProduct.id}`}
                    isNoBorder={true}
                    isShowCall={true}
                    watName={watName}
                    isFanPage={isFanPage}
                    classNameFanRemark="modal-fan-remark"
                  />
                </div>
              </TabPane>
              <TabPane tab="คำถามที่พบบ่อย" key="faq-m">
                <div className="modal-body">
                  <ModalFAQ />
                  <Divider className="secondary" />
                  <PurchaseButtonGroup
                    buttonLabelMobile="คลิก Add line เพื่อสั่งซื้อ"
                    isNoBorder={true}
                    buttonId={`MDetail-${selectedProduct.category}_${selectedProduct.id}`}
                    isShowCall={true}
                    watName={watName}
                    isFanPage={isFanPage}
                    classNameFanRemark="modal-fan-remark"
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
        <DesktopBody
          isModalOpen={isModalOpen}
          onCloseModal={onCloseModal}
          selectedProduct={selectedProduct}
          watName={watName}
          isShowTel={isShowTel}
          onToggleTel={onToggleTel}
          isFanPage={isFanPage}
        />
      </Modal>
    )
  }
}

CustomModal.defaultProps = {
  isModalOpen: false,
  isShowTel: false,
  onToggleTel: () => {},
  onCloseModal: () => {},
  selectedProduct: {},
  watName: ''
}
