import React, { Component } from 'react'
// import Fade from 'react-reveal/Fade'
// import Pulse from 'react-reveal/Pulse'
import Zoom from 'react-reveal/Zoom'

import './style.scss'

export default class HowToOrder extends Component {
  render() {
    return (
      <div className="how-to-order" id="how-to-order">
        <Zoom delay={200}>
          <div className="mobile">
            <h4>สั่งพวงหรีด</h4>
            <p>ง่ายๆ 3 ขั้นตอน</p>
          </div>
          <div className="desktop">
            <h4>สั่งพวงหรีดง่ายๆ 3 ขั้นตอน</h4>
          </div>
          <a href="https://lin.ee/o4eIau9?premium=2" target="_blank" rel="noopener noreferrer">
            <img
              className="how-to img-responsive desktop"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/how_to_order_1.jpg"
              alt="แคปรูป แอดไลน์ แจ้งรายละเอียด"
            />
            <img
              className="how-to img-responsive mobile"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/how_to_order.png"
              alt="แคปรูป แอดไลน์ แจ้งรายละเอียด"
            />
          </a>
        </Zoom>
        {/* <HeadShake delay={1000}>
          <div className="line-button">
            <img
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_line_white.png"
              alt="@tanjai.co"
            />
            <a
              href="https://lin.ee/o4eIau9?premium=3"
              target="_blank"
              rel="noopener noreferrer"
              id="navbar-line"
              title="@tanjai.co"
            >
              <span>สั่งซื้อพวงหรีด คลิก!</span>
            </a>
          </div>
        </HeadShake> */}
        {/* <div className="prop-text">
          <h4>ไว้อาลัยครั้งสุดท้ายเลือกพวงหรีดพรีเมียมให้บุคคลสำคัญ</h4>
        </div> */}
      </div>
    )
  }
}
