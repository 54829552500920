import React, { Component } from 'react'

import './style.scss'

export default class SEOSection extends Component {
  render() {
    return (
      <div className="seo-wrapper">
        <div className="seo-section">
          <p>
            ด้วยความอาลัยรัก <br /> แด่คนที่จากไปมอบความรักและความอาลัย
            ผ่านพวงหรีดดอกไม้สดสุดพิเศษจาก...
            <b>แทนใจ</b>
            แทนความรักให้กับคนสำคัญของคุณเป็นครั้งสุดท้าย ด้วยดอกไม้สดและ
            <b>วัตถุดิบระดับพรีเมียม</b>
            ที่ผ่านฝีมืออย่างปราณีต <br />
            เพื่อให้พวงหรีดออกมาดีที่สุดสมกับความรักที่ตั้งใจส่งไปถึงคนพิเศษ ด้วย
            <b>บริการส่งตรงถึงงานทั่วประเทศ</b> เพียงแค่สั่งออนไลน์ สั่งง่าย สะดวก สบาย
            ส่งถึงหน้าศาลาวัดพระศรีมหาธาตุ และวัดดังอื่นๆ ในกรุงเทพฯ <b>กว่า 150 สถานที่</b>
            <br />
            มาพร้อมกับ<b>ประสบการณ์กว่า 10 ปี</b> ที่การันตีได้ถึง
            ความเป็นมืออาชีพให้คุณเชื่อมั่นได้ในความสวยงามของพวงหรีดที่จะได้รับ และบริการที่ดีจากเรา
            สั่งออนไลน์ได้เลยตอนนี้ <br /> <b>พร้อมส่งทันทีถึงวัดในวันเดียวกัน</b>
          </p>
          <img
            className="left-quote"
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/left_quote.png"
            alt=""
          />
          <img
            className="right-quote"
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/right_quote.png"
            alt=""
          />
        </div>
      </div>
    )
  }
}
