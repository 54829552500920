import React, { Component } from 'react'

import './style.scss'

export default class ValueProp extends Component {
  render() {
    return (
      <div className="value-prop">
        <div className="value-prop-img">
          <a href="https://lin.ee/o4eIau9?premium=6" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/value_prop.png"
              alt="มี 150 สาขา ส่งตรงเวลา ออกใบกำกับภาษีได้"
            />
          </a>
        </div>
        <div className="value-prop-contact">
          <a href="https://lin.ee/o4eIau9?premium=7" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/line_button.png"
              alt="สั่งซื้อแอดไลน์ @tanjai.co (มี @ ด้วย)"
            />
          </a>
          <img
            className="img-responsive girl-welcome"
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/girl_reed.png"
            alt="แทนใจพรีเมียม ยินดีให้บริการ"
          />
        </div>
      </div>
    )
  }
}
