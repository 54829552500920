import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import CustomModal from 'src/components/CustomModal'
import PurchaseButtonGroup from 'src/components/PurchaseButtonGroup'
// import PurchaseButtonDesktop from 'src/components/PurchaseButtonDesktop'
import Badge from 'src/components/Badge'

import './style.scss'

export default class ProductPremium extends Component {
  targetRef = React.createRef()
  targetElement = null

  state = {
    isModalOpen: false,
    isShowTel: false,
    selectedProduct: {},
    isSeeMore: false,
    isMobile: false
  }

  componentDidMount() {
    this.targetElement = this.targetRef.current
    if (window.innerWidth < 768)
      this.setState({
        isMobile: true
      })
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  onOpenModal = product => {
    this.setState({ isModalOpen: true, selectedProduct: product })
    disableBodyScroll(this.targetElement)
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false, isShowTel: false })
    enableBodyScroll(this.targetElement)
  }

  onToggleTel = () => {
    this.setState({
      isShowTel: !this.state.isShowTel
    })
  }

  onSeeMore = () => {
    this.setState({
      isSeeMore: true
    })
  }

  getProductClassFromSeeMore = index => {
    const { isSeeMore, isMobile } = this.state
    if (isMobile) {
      if (index > 5) {
        if (!isSeeMore) {
          // return 'not-show'
          return ''
        } else return 'show'
      } else {
        return ''
      }
    }
  }

  getDisplaySeeMore = () => {
    const { isSeeMore, isMobile } = this.state
    if (isMobile) {
      if (isSeeMore) {
        // return 'not-show'
        return ''
      } else return ''
    } else {
      return ''
    }
  }

  render() {
    const { isModalOpen, isShowTel, selectedProduct } = this.state
    const { watName, products } = this.props
    return (
      <div className="product-premium" id="product-p">
        <div className="product-premium-content">
          <div className="category-title">
            <h4>พวงหรีดพรีเมียม</h4>
          </div>
          <div className="products-container">
            {products.length !== 0 &&
              products.map((product, index) => {
                return (
                  <React.Fragment key={index}>
                    <Zoom delay={index === 0 || index === 1 ? 500 : 200}>
                      <a
                        role="presentation"
                        className={`pp-product-item product-view ${this.getProductClassFromSeeMore(
                          index
                        )}`}
                        onClick={() => this.onOpenModal(product)}
                        onKeyDown={() => this.onOpenModal(product)}
                        id={product.id}
                      >
                        {product.best_seller && <Badge />}
                        <div className="pp-product-img">
                          <img src={product.image} alt={product.id} />
                        </div>
                        <div style={{ width: '100%', alignSelf: 'flex-end' }}>
                          <div className="horizontal-line"></div>
                          <div className="pp-product-detail">
                            <small>{product.id}</small>
                            <p>฿{Number(product.price).toLocaleString()}</p>
                            <div
                              className="see-detail-button"
                              onClick={() => this.onOpenModal(product)}
                            >
                              <img
                                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/icon_cursor.png"
                                alt="คลิก"
                              />
                              <p>ดูรายละเอียด</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </Zoom>
                    {/* {index === 5 && (
                      <div
                        className={`see-more mobile ${this.getDisplaySeeMore()}`}
                        onClick={this.onSeeMore}
                      >
                        <HeadShake delay={200} forever={true} duration={2000}>
                          <img
                            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/see_more.png"
                            alt="ดูเพิ่มเติม"
                          />
                        </HeadShake>
                      </div>
                    )} */}
                  </React.Fragment>
                )
              })}
          </div>
          <div className="limit-width-1000">
            {/* <div className="hide-lg"> */}
            <PurchaseButtonGroup watName={watName} buttonId="below-premium-product__mobile" />
            {/* </div> */}
            {/* <div className="show-lg">
              <PurchaseButtonDesktop buttonId="below-premium-product__desktop" />
            </div> */}
          </div>
        </div>

        {isModalOpen && (
          <CustomModal
            ref={this.targetRef}
            isModalOpen={isModalOpen}
            onCloseModal={this.onCloseModal}
            selectedProduct={selectedProduct}
            watName={watName}
            onToggleTel={this.onToggleTel}
            isShowTel={isShowTel}
          />
        )}
      </div>
    )
  }
}
