export const DELIVERY_LOCATIONS = [
  {
    name: 'วัดพระศรีฯ',
    position: '5'
  },
  {
    name: 'วัดเสมียนนารี',
    position: '10'
  },
  {
    name: 'วัดลาดพร้าว',
    position: '15'
  },
  {
    name: 'วัดเทพศิรินทร์',
    position: '15'
  },
  {
    name: 'วัดศรีเอี่ยม',
    position: '15'
  },
  {
    name: 'วัดศิริพงษ์ธรรมนิมิตร',
    position: '15'
  },
  {
    name: 'วัดหลักสี่',
    position: '15'
  },
  {
    name: 'วัดตรีทศเทพ',
    position: '15'
  },
  {
    name: 'วัดมกุฎกษัตริยาราม',
    position: '15'
  },
  {
    name: 'วัดบึงทองหลาง',
    position: '15'
  },
  {
    name: 'วัดธาตุทอง',
    position: '15'
  },
  {
    name: 'วัดประยุรวงศาวาส',
    position: '15'
  },
  {
    name: 'วัดโสมนัส',
    position: '15'
  },
  {
    name: 'วัดเทพลีลา',
    position: '15'
  },
  {
    name: 'วัดบัวขวัญ',
    position: '15'
  }
]
