import React from 'react'
import Fade from 'react-reveal/Fade'
import HeadShake from 'react-reveal/HeadShake'
import LazyLoad from 'react-lazyload'

import GoogleMap from 'src/components/GoogleMap'
import DeliveryLocations from 'src/components/DeliveryLocations'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'

import './style.scss'

function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <Fade delay={300}>
          <div className="contact-title">
            <h3>ติดต่อสำนักงานใหญ่</h3>
          </div>
        </Fade>
        <LazyLoad>
          <div className="contact-body">
            <Fade delay={300}>
              <div className="contact-line-oa">
                <a
                  href="https://lin.ee/o4eIau9?premium=8"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="@tanjai.co"
                  id="m-contact-line"
                  title="@tanjai.co"
                >
                  <img
                    src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/qr.png"
                    alt="@tanjai.co"
                  />
                </a>
                <div className="line-oa-button">
                  <HeadShake delay={750}>
                    <a
                      href="https://lin.ee/o4eIau9?premium=9"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="@tanjai.co"
                      id="d-contact-line"
                      title="@tanjai.co"
                    >
                      <img
                        src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/line.png"
                        alt="@tanjai.co"
                      />
                      แชท + ปรึกษาฟรี
                    </a>
                  </HeadShake>
                </div>
              </div>
            </Fade>
            <div className="contact-other">
              <Fade delay={300}>
                <div className="icon-wrapper">
                  <div className="icon-item">
                    <div>
                      <img
                        src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/bordered_icon_mail.png"
                        alt="tanjai.bkk@gmail.com"
                      />
                    </div>
                    <div>
                      <p>tanjai.bkk@gmail.com</p>
                    </div>
                  </div>
                  <div className="icon-item">
                    <div>
                      <img
                        src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/bordered_icon_pin.png"
                        alt="Office"
                      />
                    </div>
                    <div>
                      <p>
                        <span>
                          <b>สำนักงานใหญ่</b>
                        </span>
                        <br />
                        101 True Digital Park 5th Floor, Sukhumvit Rd. Bang Chak, Prakanong, Bangkok
                        10260
                      </p>
                    </div>
                  </div>
                  <div className="icon-item">
                    <a href={`tel:${getTelFormatWithCountryCode(TEL)}`} title={TEL}>
                      <div>
                        <img
                          src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/bordered_icon_phone.png"
                          alt={TEL}
                        />
                      </div>
                      <div>
                        <p>{TEL}</p>
                      </div>
                    </a>
                  </div>
                </div>
              </Fade>
              <Fade delay={300}>
                <div className="google-map">
                  <GoogleMap
                    lat={13.685302}
                    lng={100.611031}
                    wat="สำนักงานใหญ่"
                    address="101 True Digital Park 5th Floor, Sukhumvit Rd. Bang Chak, Prakanong, Bangkok 10260"
                  />
                </div>
              </Fade>
            </div>
            {/* <DeliveryLocations /> */}
          </div>
        </LazyLoad>
      </div>
    </div>
  )
}

export default Contact
