import React, { Component } from 'react'
import Scroll from 'react-scroll'
import throttle from 'lodash.throttle'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import ProductPremium from 'src/components/ProductPremium'
import ProductFan from 'src/components/ProductFan'
import FAQ from 'src/components/FAQ'
import Contact from 'src/components/Contact'
import ExampleReed from 'src/components/ExampleReed'
import HeroSection from 'src/components/HeroSection'
import StickyMenu from 'src/components/StickyMenu'
import CustomerReview from 'src/components/CustomerReview'
import FacebookSection from 'src/components/FacebookSection'
import ValuePropDesktop from 'src/components/ValuePropDesktop'
import FacebookSectionDesktop from 'src/components/FacebookSectionDesktop'
import HowToOrder from 'src/components/HowToOrder'
import StickyBottomButton from 'src/components/StickyBottomButton'
import TextValueProp from 'src/components/TextValueProp'
import CategoryMenu from 'src/components/CategoryMenu'
import ProductCategory from 'src/components/ProductCategory'
import AntdCustomModal from 'src/components/AntdCustomModal'
import { setCookie, getCookie } from '../../utility/cookies'

import './style.scss'

const Element = Scroll.Element
const scroller = Scroll.scroller

export default class WatPage extends Component {
  state = {
    products: [],
    FanProducts: [],
    isSticky: false,
    isStickyMenuDesktop: false,
    heroSectionOffsetHeight: null,
    heroSectionHeight: null,
    no1Prop: null,
    menuHeight: null,
    isStickyMenuMobile: false,
    activeMenu: 'cat-a',
    productPOffsetTop: null,
    productFOffsetTop: null,
    reviewChatOffsetTop: null,
    howToOrderOffsetTop: null,
    girlWaiSectionOffsetTop: null,
    justClickedMenu: false,
    isModalVisible: false
  }

  componentDidMount() {
    var cookies = getCookie('tanjai')
    if (cookies) {
      this.setState({ isModalVisible: false })
    } else {
      this.setState({ isModalVisible: true })
      setCookie('tanjai', 'tanjai', 365)
    }

    const {
      frontmatter: { fSection, useOwnProducts }
    } = this.props.wat
    window.addEventListener('scroll', throttle(this.handleScroll, 100))

    this.getFormattedProducts()
    setTimeout(() => {
      if (useOwnProducts) {
        this.setState({
          heroSectionHeight: document.getElementById('hero-section').offsetHeight,
          no1Prop: document.getElementById('no1-prop').offsetHeight,
          productPOffsetTop: document.getElementById('product-p').offsetTop - 70,
          productFOffsetTop:
            fSection && fSection.isShowSection
              ? document.getElementById('product-f').offsetTop - 70
              : null,
          reviewChatOffsetTop: document.getElementById('review-chat').offsetTop - 70,
          howToOrderOffsetTop: document.getElementById('how-to-order').offsetTop - 70,
          girlWaiSectionOffsetTop: document.getElementById('girl-wai').offsetTop - 70
        })
      } else {
        this.setState({
          heroSectionHeight: document.getElementById('hero-section').offsetHeight,
          no1Prop: document.getElementById('no1-prop').offsetHeight,
          productPOffsetTop: document.getElementById('product-p').offsetTop - 70,
          productFOffsetTop: document.getElementById('product-f').offsetTop - 70,
          reviewChatOffsetTop: document.getElementById('review-chat').offsetTop - 70,
          howToOrderOffsetTop: document.getElementById('how-to-order').offsetTop - 70,
          girlWaiSectionOffsetTop: document.getElementById('girl-wai').offsetTop - 70
        })
      }
    }, 1000)

    if (window.location.hash === '#review') {
      const offset = document.getElementById('navbar').offsetHeight
      setTimeout(() => {
        scroller.scrollTo('review', {
          duration: 1500,
          delay: 100,
          smooth: false,
          offset: -offset
        })
      }, 1000)
    }
    if (window.location.hash === '#vip') {
      const offset = document.getElementById('navbar').offsetHeight
      setTimeout(() => {
        scroller.scrollTo('product-p', {
          duration: 1500,
          delay: 100,
          smooth: false,
          offset: -offset
        })
      }, 1000)
    }
    if (window.location.hash === '#fan') {
      const offset = document.getElementById('navbar').offsetHeight
      setTimeout(() => {
        scroller.scrollTo('product-f', {
          duration: 1500,
          delay: 100,
          smooth: false,
          offset: -offset
        })
      }, 1000)
    }
    if (window.location.hash === '#fb-line') {
      const offset = document.getElementById('navbar').offsetHeight
      setTimeout(() => {
        scroller.scrollTo('fb-line', {
          duration: 1500,
          delay: 100,
          smooth: false,
          offset: -offset
        })
      }, 1000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', throttle(this.handleScroll, 200))
  }

  handleOk = () => {
    this.setState({ isModalVisible: false })
  }

  handleScroll = () => {
    const {
      productPOffsetTop,
      productFOffsetTop,
      reviewChatOffsetTop,
      howToOrderOffsetTop,
      justClickedMenu
    } = this.state

    if (window.innerWidth > 767) {
      // NOTE: Desktop
      this.setStickyMenuDesktop(
        window.pageYOffset,
        this.state.heroSectionHeight + this.state.no1Prop
      )
    } else {
      // NOTE: Mobile
      this.setSticky(window.pageYOffset, this.state.heroSectionHeight)
      this.setStickyMenuMobile(window.pageYOffset, howToOrderOffsetTop)
      if (!justClickedMenu) {
        if (window.pageYOffset > productPOffsetTop && window.pageYOffset < productFOffsetTop) {
          this.onClickMenu('cat-p')
        } else if (
          window.pageYOffset > productFOffsetTop &&
          window.pageYOffset < reviewChatOffsetTop
        ) {
          this.onClickMenu('cat-f')
        } else if (
          window.pageYOffset > howToOrderOffsetTop &&
          window.pageYOffset < productPOffsetTop
        ) {
          this.onClickMenu('how-to-order')
        } else {
          this.onClickMenu('')
        }
      }
    }
  }

  setSticky = (windowPageOffset, sectionHeight) => {
    if (windowPageOffset > sectionHeight - 2) {
      if (!this.state.isSticky) {
        this.setState({
          isSticky: true
        })
      }
    }
  }

  setStickyMenuMobile = (windowPageOffset, sectionHeight) => {
    if (windowPageOffset > sectionHeight) {
      if (!this.state.isStickyMenuMobile) {
        this.setState({
          isStickyMenuMobile: true
        })
      }
    }
  }

  onClickMenu = menu => {
    clearTimeout(this.timer)
    this.setState({
      activeMenu: menu,
      justClickedMenu: true
    })

    this.timer = setTimeout(() => {
      this.setState({
        justClickedMenu: false
      })
    }, 1600)
  }

  setStickyMenuDesktop = (windowPageOffset, sectionHeight) => {
    if (windowPageOffset > sectionHeight - 2) {
      if (!this.state.isSticky) {
        this.setState({
          isStickyMenuDesktop: true,
          menuHeight: document.getElementById('navbar').offsetHeight
        })
      }
    } else {
      if (this.state.isSticky) {
        this.setState({
          isStickyMenuDesktop: false,
          menuHeight: 0
        })
      }
    }
  }

  getFormattedProducts = () => {
    const {
      wat: {
        frontmatter: { fSection, premiumSection, useOwnProducts }
      }
    } = this.props

    const products = this.props.product.edges

    let tmpProducts = []
    let tmpFanProducts = []
    const formattedProducts = products // Default Products
      .map(product => {
        return {
          ...product.node.frontmatter,
          description: product.node.rawMarkdownBody,
          image: product.node.frontmatter.imageS3
            ? product.node.frontmatter.imageS3
            : product.node.frontmatter.image
        }
      })

    if (useOwnProducts) {
      const fSectionFormattedProduct = fSection.products
        .filter(product => product.selectedProduct)
        .map(product => {
          const defaultProduct = formattedProducts.find(x => x.id === product.selectedProduct)
          return this.getOverwriteProductObject(defaultProduct, product)
        })
        .sort((a, b) => {
          return a.position - b.position
        })

      const premiumSectionFormattedProduct = premiumSection.products
        .filter(product => product.selectedProduct)
        .map(product => {
          const defaultProduct = formattedProducts.find(x => x.id === product.selectedProduct)
          return this.getOverwriteProductObject(defaultProduct, product)
        })
        .sort((a, b) => {
          return a.position - b.position
        })

      tmpFanProducts = fSectionFormattedProduct
      tmpProducts = premiumSectionFormattedProduct
    } else {
      tmpProducts = formattedProducts
        .filter(product => product.category !== 'F')
        .sort((a, b) => {
          return a.position - b.position
        })

      tmpFanProducts = formattedProducts
        .filter(product => product.category === 'F')
        .sort((a, b) => {
          return a.position - b.position
        })
    }

    this.setState({ products: tmpProducts, fanProducts: tmpFanProducts })
  }

  getOverwriteProductObject = (defaultProduct, watProduct) => {
    return {
      ...defaultProduct,
      price:
        watProduct.price && watProduct.price !== '' && watProduct.price !== null
          ? watProduct.price
          : defaultProduct.price,
      position:
        watProduct.position !== 0 && watProduct.position !== '' && watProduct.position !== null
          ? watProduct.position
          : defaultProduct.position,
      isShow: watProduct.isShow === true ? true : defaultProduct.isShow,
      badge: watProduct.best_seller,
      image: defaultProduct.imageS3 ? defaultProduct.imageS3 : defaultProduct.image
    }
  }

  getPaddingForStickyMenu = () => {
    const { isStickyMenuMobile, menuHeight } = this.state
    if (isStickyMenuMobile) {
      return 66
    } else {
      return menuHeight
    }
  }

  render() {
    const {
      products,
      isSticky,
      isStickyMenuDesktop,
      fanProducts,
      isStickyMenuMobile,
      activeMenu
    } = this.state
    const { wat } = this.props
    const {
      frontmatter: { buttonLabel, name, faq, fSection, useOwnProducts }
    } = wat

    return (
      <Layout buttonLabel={buttonLabel} watName={name} productsDetail={wat.frontmatter}>
        <div style={{ paddingTop: this.getPaddingForStickyMenu() }}>
          <SEO
          // title="Home"
          // placeName={name}
          // metaDescription={metaDescription}
          // metaTitle={metaTitle}
          />
          <StickyBottomButton />
          <HeroSection watName={name} />
          <ValuePropDesktop />
          <StickyBottomButton isSticky={isSticky} />
          <StickyMenu isSticky={isStickyMenuDesktop} />
          <Element name="review">
            <ExampleReed />
          </Element>
          <Element name="howToOrder">
            <HowToOrder />
          </Element>
          <TextValueProp watName={name} />
          <ProductCategory onClickMenu={this.onClickMenu} />
          <CategoryMenu
            isStickyMenuMobile={isStickyMenuMobile}
            onClickMenu={this.onClickMenu}
            activeMenu={activeMenu}
          />
          <Element name="productP">
            <ProductPremium products={products} categoryTitle={`แบบพรีเมียม`} />
          </Element>
          {useOwnProducts ? (
            fSection &&
            fSection.isShowSection && (
              <Element name="productF">
                <ProductFan products={fanProducts} />
              </Element>
            )
          ) : (
            <Element name="productF">
              <ProductFan products={fanProducts} />
            </Element>
          )}
          <Element name="fb-line">
            <CustomerReview />
          </Element>
          <FacebookSection />
          <FacebookSectionDesktop />
          <FAQ watName={`${name}`} faq={faq} />
          <Contact />
        </div>
        {/* <AntdCustomModal isModalOpen={this.state.isModalVisible} onCloseModal={this.handleOk} /> */}
      </Layout>
    )
  }
}
