import React, { useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'

import './style.scss'

const StickyBottomButton = props => {
  const [stlyeContact, setStlyeContact] = useState({ bottom: '0px' })
  const { isSticky } = props
  useEffect(() => {
    if (typeof window !== 'undefined') {
      scrollAnimation()
    }
  }, [])

  const scrollAnimation = () => {
    var prevScrollpos = window.pageYOffset
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset
      if (prevScrollpos > currentScrollPos) {
        setStlyeContact({ bottom: '0px' })
      } else {
        setStlyeContact({ bottom: '-120px' })
      }
      prevScrollpos = currentScrollPos
    }
  }
  if (isSticky)
    return (
      <Fade>
        <div className="bottom-button mobile" style={stlyeContact}>
          {/* Call Button */}
          <div className="action-button-wrapper">
            <div className="action-button brown">
              <img
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_telephone_white.png"
                alt={TEL}
              />
              <a
                id="header-call-mobile"
                href={`tel:${getTelFormatWithCountryCode(TEL)}`}
                title={TEL}
              >
                โทรสอบถาม
              </a>
            </div>

            {/* Line Button */}
            <div className="action-button green">
              <img
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_line_white.png"
                alt="@tanjai.co"
              />
              <a
                href="https://lin.ee/o4eIau9?premium=1"
                target="_blank"
                rel="noopener noreferrer"
                id="navbar-line"
                title="@tanjai.co"
              >
                <span>สั่งซื้อพวงหรีด</span>
              </a>
            </div>
          </div>

          <p>ตอบเร็ว มีเจ้าหน้าที่ดูแลจนกว่าพวงหรีดถึงงาน</p>
        </div>
      </Fade>
    )
  else {
    return ''
  }
}

StickyBottomButton.defaultProps = {
  isSticky: null
}

StickyBottomButton.propTypes = {
  isSticky: propTypes.bool
}

export default StickyBottomButton
