import React from 'react'
import Fade from 'react-reveal/Fade'

import { HOME_IMG_LIST } from './Img/homeReviewImg'
import { FAN_IMG_LIST } from './Img/fanReviewImg'

import './style.scss'

const LOGOS = [
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/egat_logo-min.jpg',
    alt: 'การไฟฟ้านครหลวง'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/SCG_logo-min.jpg',
    alt: 'SCG'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/PTT_logo-min.jpg',
    alt: 'ปตท.'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/K-bank_logo-min.jpg',
    alt: 'ธนาคารกสิกร'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/Kerrry_logo-min.jpg',
    alt: 'Kerry Express'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/KTB-logo-min.jpg',
    alt: 'KTB'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/MU.jpg',
    alt: 'คณะแพทยศาสตร์ศิริราชพยาบาล'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/thaiairways_Logo-min.jpg',
    alt: 'การบินไทย'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/CU_Logo-min.jpg',
    alt: 'CU'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/CP_logo-min.jpg',
    alt: 'CP'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/AIA_logo-min.jpg',
    alt: 'AIA'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/Thaipakan_logo-min.jpg',
    alt: 'ไทยประกันชีวิต'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/workpoint_logo-min.jpg',
    alt: 'Workpoint'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/BDMS_logo-min.jpg',
    alt: 'BDMS'
  },
  {
    logoImg: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/reviews/logos/TTT_logo-min.jpg',
    alt: 'ททท'
  }
]

const ExampleReed = ({ isFanPage }) => {
  const getReviewImgList = () => {
    if (isFanPage) return FAN_IMG_LIST
    else return HOME_IMG_LIST
  }

  return (
    <Fade delay={300}>
      <div className="example-reed">
        <div className="container">
          <h3>ได้รับความไว้วางใจจาก</h3>
          <div className="logos">
            {LOGOS.map((logo, index) => {
              return (
                <Fade key={index} delay={index * 200 + 1000}>
                  <div className="logo-item">
                    <img src={logo.logoImg} alt={logo.alt} />
                  </div>
                </Fade>
              )
            })}
          </div>
          <div className="reeds">
            {getReviewImgList().map((item, index) => {
              return (
                <div key={index} className="reed-item">
                  <img src={item.src} alt={item.alt} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default ExampleReed
