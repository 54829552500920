import React, { Component } from 'react'
import { FacebookProvider, Page } from 'react-facebook'

import ValueProp from 'src/components/ValueProp'
import SEOSection from 'src/components/SEOSection'

import './style.scss'

export default class FacebookSectionDesktop extends Component {
  render() {
    return (
      <div className="facebook-desktop desktop">
        <h3>หน้าเพจ Facebook</h3>

        <div className="facebook-desktop-content">
          <div className="left-box">
            <div className="facebook-plugin">
              <FacebookProvider appId="198714437399062">
                <Page
                  href="https://www.facebook.com/tanjai.co/"
                  tabs="timeline"
                  height="500px"
                  width="500px"
                  smallHeader={true}
                  hideCover={true}
                />
              </FacebookProvider>
            </div>
          </div>

          <div className="right-box">
            <ValueProp />
            <SEOSection />
          </div>
        </div>
      </div>
    )
  }
}
