import React from 'react'
import styled from 'styled-components'

const CustomBadge = styled.div`
  position: absolute;
  left: -1px;
  top: -1px;
`

const FlagGreenEle = styled.img`
  width: 67px;
  height: auto;
  @media (min-width: 768px) {
    width: 8vw;
  }
`

const BadgeText = styled.span`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 100%;
  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 25px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 20px;
  }
`

const Star = styled.img`
  width: 20px;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 22px;

  @media (min-width: 768px) {
    top: 55%;
    width: 40px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    top: 58%;
    width: 28px;
  }
`

const Badge = () => {
  return (
    <CustomBadge>
      <FlagGreenEle src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/flag_red.png" alt="ขายดี" />
      <BadgeText>ขายดี</BadgeText>
      <Star
        className="star"
        src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/starx3.png"
        alt="ขายดี"
      />
    </CustomBadge>
  )
}

export default Badge
