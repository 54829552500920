import React, { Component } from 'react'
import { FacebookProvider, Page } from 'react-facebook'

import ValueProp from 'src/components/ValueProp'
import SEOSection from 'src/components/SEOSection'

import './style.scss'
export default class FacebookSection extends Component {
  render() {
    return (
      <div className="facebook-section mobile">
        <h3>หน้าเพจ Facebook</h3>
        <div className="facebook-plugin">
          <FacebookProvider appId="198714437399062">
            <Page
              href="https://www.facebook.com/tanjai.co/"
              tabs="timeline"
              height="320px"
              width="400px"
              smallHeader={true}
              hideCover={true}
            />
          </FacebookProvider>
        </div>
        <ValueProp />
        <SEOSection />
      </div>
    )
  }
}
