import React, { Component } from 'react'
import Slider from 'react-slick'

import './style.scss'

const settings = {
  infinite: true,
  // centerMode: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  autoplay: true,
  speed: 500,
  pauseOnHover: true,
  // variableWidth: true
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export default class CustomerReview extends Component {
  render() {
    return (
      <div className="customer-review" id="review-chat">
        <div className="customer-review-title mobile">
          <img
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/no1_badge.png"
            alt="No.1 เรื่องพวงหรีด"
          />
          <h3>พวงหรีดออนไลน์อันดับ1</h3>
        </div>

        <div className="customer-review-desc mobile">
          <p>
            มอบความรักและความอาลัย <br />
            ผ่านพวงหรีดดอกไม้สด <span>‘แทนใจ’</span>
          </p>
        </div>

        <div className="customer-review-title desktop">
          <h3>รีวิวจากลูกค้า</h3>
        </div>

        <div className="customer-review-chats">
          <Slider {...settings}>
            {REVIEW_IMG_LIST.map((item, index) => {
              return <img key={index} src={item.src} alt={item.alt} />
            })}
          </Slider>
        </div>
      </div>
    )
  }
}

const REVIEW_IMG_LIST = [
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_01-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_03-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_04-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_05-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_07-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_08-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_09-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_10-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_11-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/chat-reviews/chat_review_12-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  }
]
