export const HOME_IMG_LIST = [
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_00.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_01.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_02.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_03.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },

  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_05.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_08.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_09.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_10.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_11.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/review/Review-premium_12.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @tanjai.co'
  }
]
