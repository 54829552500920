import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'
import Slider from 'react-slick'

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import Divider from 'src/components/Divider'
import CustomModal from 'src/components/CustomModal'
import PurchaseButtonGroup from 'src/components/PurchaseButtonGroup'
// import PurchaseButtonDesktop from 'src/components/PurchaseButtonDesktop'

import './style.scss'

export default class ProductFan extends Component {
  targetRef = React.createRef()
  targetElement = null

  state = {
    isModalOpen: false,
    isShowTel: false,
    selectedProduct: {}
  }

  componentDidMount() {
    this.targetElement = this.targetRef.current
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  onOpenModal = product => {
    let selectedProduct
    if (product.length > 1) {
      selectedProduct = {
        ...product[0],
        m: {
          id: product[1].id,
          price: product[1].price,
          size: product[1].size,
          description: product[1].description,
          descMobile: product[1].descMobile
        }
      }
    } else {
      selectedProduct = {
        ...product[0]
      }
    }
    this.setState({ isModalOpen: true, selectedProduct })
    disableBodyScroll(this.targetElement)
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false, isShowTel: false })
    enableBodyScroll(this.targetElement)
  }

  groupBy = (objectArray, property) => {
    const { isFanPage } = this.props
    return objectArray.reduce(function(total, obj) {
      let key
      if (isFanPage) {
        key = obj.fanBangkok.position
      } else {
        key = obj[property]
      }

      if (!total[key]) {
        total[key] = []
      }
      total[key].push(obj)
      return total
    }, {})
  }

  getProducts = () => {
    const positionGroup = this.groupBy(this.props.products, 'position')
    const keyArray = Object.keys(positionGroup)
    let fanProducts = keyArray.map(key => {
      return positionGroup[key].sort((a, _) => {
        if (a.id.includes('F1')) return -1
        else return 1
      })
    })
    return fanProducts
  }

  onToggleTel = () => {
    this.setState({
      isShowTel: !this.state.isShowTel
    })
  }

  getSlider = products => {
    if (products) {
      if (products.length !== 0) {
        const settings = {
          dots: true,
          infinite: false,
          speed: 250,
          slidesToShow: products.length >= 4 ? 4 : products.length,
          slidesToScroll: 4,
          arrows: true,
          autoplay: false,
          customPaging: i => <div>{i + 1}</div>,
          pauseOnHover: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        }
        return (
          <Slider {...settings}>
            {this.getProducts().map((product, index) => {
              const [s, m] = product
              return (
                <Zoom key={index}>
                  <a
                    role="presentation"
                    className="pf-product-item-wrapper product-view"
                    onClick={() => this.onOpenModal(product)}
                    onKeyDown={() => this.onOpenModal(product)}
                    id={s.id}
                  >
                    <div className="pf-product-item">
                      <div className="pf-product-img">
                        <img src={s.imageS3 ? s.imageS3 : s.image} alt={s.id} />
                      </div>
                      <Divider className="secondary" />
                      <div className="pf-product-detail-wrapper">
                        <div className="pf-product-detail">
                          <small>{s.id}</small>
                          <p>฿{Number(s.price).toLocaleString()}</p>
                          <div className="tag xs">size {s.size}</div>
                        </div>
                        {m && (
                          <>
                            <div className="divider-secondary" />
                            <div className="pf-product-detail">
                              <small>{m.id}</small>
                              <p>฿{Number(m.price).toLocaleString()}</p>
                              <div className="tag xs">size {m.size}</div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </a>
                </Zoom>
              )
            })}
          </Slider>
        )
      }
    } else {
      return ''
    }
  }

  render() {
    const { isModalOpen, isShowTel, selectedProduct } = this.state
    const { watName, products } = this.props
    return (
      <div className="product-fan" id="product-f">
        <div className="container">
          <div className="category-title">
            <h4>พวงหรีดพัดลม</h4>
          </div>
          <div className="slider-product-wrapper">
            {products &&
              this.getProducts().map((product, index) => {
                const [s, m] = product
                return (
                  <Zoom key={index} delay={200}>
                    <a
                      role="presentation"
                      className="pf-product-item-wrapper product-view"
                      onClick={() => this.onOpenModal(product)}
                      onKeyDown={() => this.onOpenModal(product)}
                      id={s.id}
                    >
                      <div className="pf-product-item">
                        <div className="pf-product-img">
                          <img src={s.imageS3 ? s.imageS3 : s.image} alt={s.id} />
                        </div>
                        <Divider className="secondary" />
                        <div className="pf-product-detail-wrapper">
                          <div className="pf-product-detail">
                            <small>{s.id}</small>
                            <p>฿{Number(s.price).toLocaleString()}</p>
                            <div className="tag xs">size {s.size}</div>
                          </div>
                          {m && (
                            <>
                              <div className="divider-secondary" />
                              <div className="pf-product-detail">
                                <small>{m.id}</small>
                                <p>฿{Number(m.price).toLocaleString()}</p>
                                <div className="tag xs">size {m.size}</div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className="see-detail-button"
                          onClick={() => this.onOpenModal(product)}
                        >
                          <img
                            className="img-responsive"
                            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/icon_cursor.png"
                            alt="คลิก"
                          />
                          <p>ดูรายละเอียด</p>
                        </div>
                      </div>
                    </a>
                  </Zoom>
                )
              })}
          </div>
          <div style={{ marginTop: 5 }} className="limit-width-1000">
            {/* <div className="hide-lg"> */}
            <PurchaseButtonGroup
              watName={watName}
              isFanPage={true}
              buttonId="below-fan-product__mobile"
            />
            {/* </div> */}
            {/* <div className="show-lg">
              <PurchaseButtonDesktop isFanPage={true} buttonId="below-fan-product__desktop" />
            </div> */}
          </div>
        </div>

        {isModalOpen && (
          <CustomModal
            ref={this.targetRef}
            isModalOpen={isModalOpen}
            onCloseModal={this.onCloseModal}
            selectedProduct={selectedProduct}
            watName={watName}
            onToggleTel={this.onToggleTel}
            isShowTel={isShowTel}
            isFanPage={true}
          />
        )}
      </div>
    )
  }
}
