import React, { Component } from 'react'
// import Slider from 'react-slick'

// import { DELIVERY_LOCATIONS } from 'static/config/delivery-locations'
import PainAndGainSection from 'src/components/PainAndGainSection'
import './style.scss'

// const settings = {
//   infinite: true,
//   centerMode: true,
//   // slidesToShow: 3,
//   slidesToScroll: 1,
//   arrows: false,
//   dots: false,
//   autoplay: true,
//   speed: 500,
//   pauseOnHover: true,
//   variableWidth: true
// }

export default class HeroSection extends Component {
  state = {
    isLoading: true
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: false
      })
    }, 800)
  }

  render() {
    // const { isLoading } = this.state
    const { watName } = this.props
    return (
      <div className="hero-container" id="hero-section">
        <div className="hero-logo">
          <img
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/logo_tanjai_premium_g.png"
            alt="แทนใจพรีเมียม"
          />
        </div>

        <div className="hero-content">
          <p className="tag-line">ร้านพวงหรีด{watName ? watName : ' ออนไลน์'} No.1</p>
          <PainAndGainSection />
          {/* {!isLoading && (
            <div className="hero-locations">
              <Slider {...settings}>
                {DELIVERY_LOCATIONS.map((location, index) => {
                  return (
                    <div key={index} className="hero-location-item">
                      <img
                        id="pin-gold"
                        src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/pin_dark_gold.png"
                        alt="location"
                      />
                      <span>{location.name}</span>
                    </div>
                  )
                })}
              </Slider>
            </div>
          )} */}

          {/* <div className="hero-prop">
            <div className="hero-prop-box">
              <p>
                จัดส่ง <span className="gold">FREE</span> วัดดัง <span>ทั่วกรุงเทพ</span>
              </p>
            </div>
          </div> */}

          {/* <div className="hero-prop-mobile mobile">
            <img
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/prop_camera_mobile.png"
              alt="ส่งตรงเวลา มีรูปยืนยัน"
            />
            <img
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/prop_vat_mobile.png"
              alt="ออก VAT ได้ทันที"
            />
          </div> */}

          <div className="hero-reed">
            {/* BEGIN: MOBILE */}
            <img
              className="mobile img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/hero_reed_mobile_v1.png"
              alt="@tanjai.co"
            />
            {/* END: MOBILE */}

            {/* BEGIN: DESKTOP */}
            <img
              className="desktop"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/reed_blue.png"
              alt="@tanjai.co"
            />
            <img
              className="desktop"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/reed_white.png"
              alt="@tanjai.co"
            />
            <img
              className="desktop"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/reed_pink.png"
              alt="@tanjai.co"
            />
            {/* END: DESKTOP */}
          </div>
        </div>
      </div>
    )
  }
}
