import React, { Component } from 'react'

import './style.scss'

export default class ValuePropDesktop extends Component {
  render() {
    return (
      <div className="desktop no1-prop" id="no1-prop">
        <div className="no1-prop-header">
          <div className="no1-prop-badge">
            <img
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/no1_badge_1.png"
              alt="No.1 เรื่องพวงหรีด"
            />
          </div>

          <div className="no1-prop-title">
            <h1>พวงหรีดออนไลน์อันดับ 1</h1>
            <p>
              มอบความรักและความอาลัย ผ่านพวงหรีดดอกไม้สด <span>‘แทนใจ’</span>
            </p>
          </div>
        </div>

        <div className="no1-prop-img">
          <a href="https://lin.ee/o4eIau9?premium=A3" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/value_prop_desktop.png"
              alt="No.1 เรื่องพวงหรีด"
            />
          </a>
        </div>
      </div>
    )
  }
}
