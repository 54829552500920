import React, { Component } from 'react'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'

import './style.scss'

export default class StickyMenu extends Component {
  render() {
    const { isSticky } = this.props
    return (
      <div className={`desktop menu ${isSticky ? 'sticky' : ''}`} id="navbar">
        {isSticky && (
          <div className="menu-logo mobile">
            <img
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/logo_tanjai_premium_g.png"
              alt="แทนใจพรีเมียม"
            />
          </div>
        )}
        <div className="menu-logo desktop">
          <img
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/logo_tanjai_premium_g.png"
            alt="แทนใจพรีเมียม"
          />
        </div>

        <div className="menu-button desktop">
          {/* Call Button */}
          <div className="menu-button-item call">
            <img
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_telephone_white.png"
              alt={TEL}
            />
            <a id="header-call-mobile" href={`tel:${getTelFormatWithCountryCode(TEL)}`} title={TEL}>
              {TEL}
            </a>
          </div>

          {/* Line Button */}
          <div className="menu-button-item line">
            <img
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_line_white.png"
              alt="@tanjai.co"
            />
            <a
              href="https://lin.ee/o4eIau9?premium=1"
              target="_blank"
              rel="noopener noreferrer"
              id="navbar-line"
              title="@tanjai.co"
            >
              <span>สั่งซื้อพวงหรีด กด!</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
