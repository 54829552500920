import React, { Component } from 'react'
import styled from 'styled-components'

const Box = styled.div`
  padding: 15px;
`

export default class HowToOrder extends Component {
  render() {
    return (
      <Box>
        <img
          className="img-responsive"
          src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/how_to_modal.png"
          alt="สั่งซื้อเพียง add line: @tanjai.co (มี @ ด้วย)"
        />
      </Box>
    )
  }
}
