import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'

import './style.scss'

export default class PainAndGainSection extends Component {
  render() {
    return (
      <div className="pain-gain mobile">
        <div className="pain-gain-wrapper">
          <Zoom delay={100}>
            <div className="pain-gain-item">
              <p>
                <span>&#10003;</span> พวงหรีดสวยหรู ประณีตสมเกียรติ
              </p>
            </div>
          </Zoom>
          <Zoom delay={300}>
            <div className="pain-gain-item">
              <p>
                <span>&#10003;</span> การันตี ไม่ถึงงาน{' '}
                <b>
                  <u>
                    <i>คืนเงิน100%</i>
                  </u>
                </b>
              </p>
            </div>
          </Zoom>
          <Zoom delay={500}>
            <div className="pain-gain-item">
              <p>
                <span>&#10003;</span> ส่งทั่วประเทศ มีเครือข่ายกว่า150สาขา
              </p>
            </div>
          </Zoom>
          <Zoom delay={700}>
            <div className="pain-gain-item">
              <p>
                <span>&#10003;</span> บริการดี ทักแล้ว{' '}
                <b>
                  <u>
                    <i>ตอบทันที</i>
                  </u>
                </b>
              </p>
            </div>
          </Zoom>
          <Zoom delay={900}>
            <div className="pain-gain-item">
              <p>
                <span>&#10003;</span> ลูกค้าองค์กร ออกVATได้
              </p>
            </div>
          </Zoom>
          <Zoom delay={1100}>
            <div className="pain-gain-item">
              <p>
                <span>&#10003;</span> ดูแลจนถึงงาน{' '}
                <b>
                  <u>
                    <i>มีรูปยืนยัน</i>
                  </u>
                </b>
              </p>
            </div>
          </Zoom>
        </div>
      </div>
    )
  }
}
