import React, { Component } from 'react'

import { DELIVERY_LOCATIONS } from 'static/config/delivery-locations'

import './style.scss'

export default class DeliveryLocations extends Component {
  render() {
    return (
      <div className="delivery-section">
        <h3>สถานที่จัดส่งพวงหรีด</h3>
        <h4>กรุงเทพ</h4>
        <ul>
          {DELIVERY_LOCATIONS.map((location, index) => {
            return <li key={index}>พวงหรีด{location.name}</li>
          })}
        </ul>
        <div className="delivery-prop">
          <a href="https://lin.ee/o4eIau9?premium=A1" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/free_delivery.png"
              alt="@tanjai.co"
            />
          </a>
        </div>
      </div>
    )
  }
}
