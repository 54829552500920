import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import LazyLoad from 'react-lazyload'

import CustomFAQ from 'src/components/CustomFAQ'

import './style.scss'

export default class FAQ extends Component {
  render() {
    const { watName, faq } = this.props
    return (
      <Fade delay={300}>
        <div className="faq">
          <div className="container">
            <div>
              <h3>
                คำถามที่พบบ่อย
              </h3>
              {/* <Divider /> */}
            </div>
            <LazyLoad>
              <CustomFAQ watName={watName} faq={faq} />
            </LazyLoad>
          </div>
        </div>
      </Fade>
    )
  }
}
